import { inject } from '@angular/core';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import { contentApiConfig } from '@config/apis/content/content-api.config';
import { ApolloClientOptions } from '@apollo/client/core';
import { environment } from '@environments/environment';

export const apolloConfig = (): ApolloClientOptions<NormalizedCacheObject> => {
  const httpLink = inject(HttpLink);

  return {
    link: httpLink.create({
      uri: FfNgxUrlHelper.createUrl(
        contentApiConfig.baseUrl,
        contentApiConfig.endpoints.POST_GRAPHQL.path,
      ).toString(),
    }),
    cache: new InMemoryCache(),
    devtools: {
      name: 'min-side',
      enabled: environment.apolloClientSettings.enableDevTools,
    },
    // other options...
  };
};
