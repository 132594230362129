import {
  FF_NGX_REQUEST_BUNDLE_API_CONFIGS,
  FfNgxRequestBundleConfig,
} from '@fagforbundet/ngx-components';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { cmsApiConfig } from '@config/apis/cms/cms-api.config';
import { idApiConfig } from '@config/apis/id/id-api.config';
import { membershipApiConfig } from '@config/apis/membership/membership-api.config';
import { proxyApiConfig } from '@config/apis/proxy/proxy-api.config';
import { environment } from '@environments/environment';
import { notificationApiConfig } from '@config/apis/notification/notification-api.config';

export const requestBundleConfig: FfNgxRequestBundleConfig = {
  debug: false,
  ttl: environment.requestBundle.ttl,
};

export function apiConfigurationProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_REQUEST_BUNDLE_API_CONFIGS,
      useValue: [
        cmsApiConfig,
        idApiConfig,
        membershipApiConfig,
        notificationApiConfig,
        proxyApiConfig
      ],
      multi: true,
    },
  ]);
}
