import { NgxDirectusContentHandlerConfig } from '@halloverden/ngx-directus-content-handler';
import { environment } from '@environments/environment';

export const ngxDirectusContentHandlerConfig: NgxDirectusContentHandlerConfig = {
  debug: environment.debugSettings.ngxDirectusContentHandler,
  contentHandlers: [],
  markHandlers: [],
  builtinContentHandlerConfig: {
    heading: {
      h1: {
        class: 'ff-h1'
      },
      h2: {
        class: 'ff-h2'
      },
      h3: {
        class: 'ff-h3'
      },
      h4: {
        class: 'ff-h4'
      },
      h5: {
        class: 'ff-h5'
      },
      h6: {
        class: 'ff-h6'
      },
    },
    table: {
      table: {
        class: 'ff-table'
      },
      tr: {
        class: 'ff-tr'
      },
      th: {
        class: 'ff-th'
      },
      td: {
        class: 'ff-td'
      }
    }
  },
  builtinMarkHandlerConfig: {
    link: {
      class: 'ff-link'
    }
  }
};
