import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import { authnConfig } from './authn.config';
import { loadingScreenConfig } from '@config/loading-screen.config';
import { provideRouterWithConfig } from '@config/router.config';
import { authBundleConfig } from '@config/auth-bundle.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  apiConfigurationProvider,
  requestBundleConfig,
} from '@config/request-bundle.config';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/title-strategies/page-title-strategy';
import { CookiebotConfig } from '@config/cookiebot.config';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service.factory';
import { piwikProConfig } from '@config/piwik-pro.config';
import { initializeInMemoryStoreFactory } from '@config/in-memory-store-initializer.factory';
import {
  ffNgxLoadingScreenProvider,
  ffNgxAuthBundleProvider,
  FfNgxAuthOidcCustomStore,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  FfNgxStorageService,
  ffNgxPiwikProProvider,
  ffNgxFormErrorProvider,
  FfNgxDateParserFormatter,
  NgbDateParserFormatter,
} from '@fagforbundet/ngx-components';
import { controlErrorMessages } from '@config/control-errors/control-error.config';
import { MockInterceptor } from '@core/interceptors/mock.interceptor';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';
import { MaintenanceInterceptor } from '@core/interceptors/maintenance.interceptor';
import { provideApollo } from 'apollo-angular';
import { apolloConfig } from '@config/apollo.config';
import { ngxDirectusContentHandlerConfig } from '@config/ngx-directus-content-handler.config';
import { provideNgxDirectusContentHandler } from '@halloverden/ngx-directus-content-handler';

export const appConfig: ApplicationConfig = {
  providers: [
    apiConfigurationProvider(),
    ffNgxAuthBundleProvider(authnConfig, authBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    ffNgxLoadingScreenProvider(loadingScreenConfig),
    ffNgxPiwikProProvider(piwikProConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    importProvidersFrom(AuthModule),
    ngxCookiebotProvider(CookiebotConfig),
    provideAnimations(),
    provideHttpClient(withInterceptors([])),
    provideRouterWithConfig(),
    provideApollo(apolloConfig),
    provideNgxDirectusContentHandler(ngxDirectusContentHandlerConfig),
    provideAppInitializer(() => {
        const initializerFn = (cookieConsentServiceFactory)(inject(CookieConsentService));
        return initializerFn();
      }),
    provideAppInitializer(() => {
        const initializerFn = (initializeInMemoryStoreFactory)(inject(FfNgxStorageService));
        return initializerFn();
      }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: AbstractSecurityStorage,
      useClass: FfNgxAuthOidcCustomStore,
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: FfNgxDateParserFormatter,
    },
  ],
};
